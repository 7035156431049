<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="16">
          <a-col :md="6" :sm="24">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.orderNo" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="下单人手机号">
              <a-input v-model="queryParam.mobile" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="运输状态">
              <a-select v-model="queryParam.transStatus" placeholder="请选择" default-value="0">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">待调度</a-select-option>
                <a-select-option value="1">运输中</a-select-option>
                <a-select-option value="2">已到达</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="企业客户">
              <!--              <a-input v-model="queryParam.paymentCustomerCode" style="width: 100%" placeholder="客户识别码" />-->
              <a-select v-model="queryParam.paymentCustomerCode" placeholder="请选择" >
                <a-select-option v-for="item in qyCustomers" :key="item.code">{{ item.nickname }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :md="6" :sm="24">
            <a-form-item label="下单时间从：">
              <a-date-picker v-model="queryParam.orderDateStart" style="width: 100%" placeholder="请输入日期" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="到" class="dislodge-script">
              <a-date-picker v-model="queryParam.orderDateEnd" style="width: 100%" placeholder="请输入日期" />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-item label="回单状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">未回单</a-select-option>
                <a-select-option value="6">已回单</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" @click="updateStatus(5)" v-action:but_tlt_trans_status>到达</a-button>&nbsp;&nbsp;
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{y: scrollHeight, x: 'max-content'}"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->

      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="orderInfo(record)" v-action:but_tlt_trans_orderinfo>详情&ensp;|&ensp;</a>
          <!--如果需要权限 v-action:roleBD -->

          <span v-show="record.transStatus<=0"><a @click="openTransport(record)"  v-action:but_tlt_trans_dispatch>调度&ensp;|&ensp;</a></span>
          <span v-show="record.transStatus>0"><a @click="unTransport(record)"    v-action:but_un_tlt_trans_dispatch>撤回&ensp;|&ensp;</a></span>
          <span v-show="record.transStatus==2 && record.status!=6"><a @click="receiptOrder(record)"   v-action:but_tlt_orderpic_add>回单&ensp;|&ensp;</a></span>
          <a @click="exception(record)" v-action:but_tlt_trans_exception>异常</a>
        </template>
      </span>
    </s-table>

    <a-modal
      key="transport"
      title="调度"
      :width="500"
      :visible="visible_add"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="handleCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div>
        <a-form @submit="handleSubmit" :form="form" class="dispatch-form">
          <a-form-item
            label="司机"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }"
          >
            <a-select
              placeholder="请选择司机"
              @change="handleChange"
              v-decorator="['driverId', { rules: [{ required: true, message: '请选择司机' }] }]"
            >
              <a-select-option v-for="(item, index) in optionsDriverList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            label="司机姓名"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }"
          >
            <a-input
              v-decorator="['name', { rules: [{ required: true, message: '请选择司机' }] }]"
              readonly="true"
              name="name"
            />
          </a-form-item>
          <a-form-item
            label="司机电话"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }"
          >
            <a-input
              v-decorator="['mobile', { rules: [{ required: true, message: '请选择司机' }] }]"
              readonly="true"
              name="mobile"
            />
          </a-form-item>

          <a-form-item
            label="车牌号"
            :labelCol="{lg: {span: 7}, sm: {span: 7}}"
            :wrapperCol="{lg: {span: 15}, sm: {span: 17} }"
          >
            <a-select placeholder="请选择车辆" @change="handleChange2" v-decorator="['vehicleId']">
              <a-select-option v-for="(item, index) in optionsVehicleList" :key="index" :value="item.id">
                {{ item.vno }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :wrapperCol="{ span: 24 }" class="form-submit">
            <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

    <a-modal
      key="exception"
      title="异常"
      :width="460"
      :visible="visible_add2"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="handleCancel2"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div>
        <a-form @submit="handleSubmit2" :form="form" :selfUpdate="true">
          <a-form-item
            label="发生时间"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-date-picker
              showTime
              v-decorator="['businessDate', { rules: [{ required: true, message: '请输入时间' }] }]"
              name="businessDate"
              style="width: 100%"
              placeholder="请输入日期"
            />
          </a-form-item>

          <a-form-item
            label="异常类型"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-select placeholder="请选择异常类型"
                      v-decorator="['type',{ rules: [{ required: true, message: '请选择类型' }] }]">
              <a-select-option value="1">延迟</a-select-option>
              <a-select-option value="2">货损</a-select-option>
              <a-select-option value="3">拒绝签收</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="内容描述"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <a-textarea v-decorator="[
              'info',
               {rules: [{validator: handleConfirmPass}], validateTrigger: 'blur'}
            ]" name="info"></a-textarea>
          </a-form-item>

          <a-form-item
            label="照片"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <table>
              <tr>
                <td>
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest1"
                  >
                    <img id="pic1" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </td>
                <td>
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest2"
                  >
                    <img id="pic2" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </td>
                <td>
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest3"
                  >
                    <img id="pic3" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </td>
              </tr>
              <tr>
                <td>
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest4"
                  >
                    <img id="pic4" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </td>
                <td>
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest5"
                  >
                    <img id="pic5" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </td>
              </tr>
            </table>
          </a-form-item>
          <a-form-item :wrapperCol="{ span: 24 }" class="form-submit">
            <a-button style="margin-right: 8px" @click="handleCancel2">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

    <a-modal
      key="receipt"
      title="回单"
      :width="460"
      :visible="visible_receipt"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="handleCancel3"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div>
        <a-form @submit="handleSubmit3" :form="form">
<!--          <a-form-item-->
<!--            label="签收地址"-->
<!--            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"-->
<!--            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"-->
<!--          >-->
<!--            <a-select placeholder="请选地址" v-decorator="['type']">-->
<!--              <a-select-option value="1">延迟</a-select-option>-->
<!--              <a-select-option value="2">货损</a-select-option>-->
<!--              <a-select-option value="3">拒绝签收</a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-item>-->

          <a-form-item
            label="照片"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
          >
            <table>
              <tr>
                <td>
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest21"
                  >
                    <img id="pic21" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </td>
                <td>
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest22"
                  >
                    <img id="pic22" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </td>
                <td>
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest23"
                  >
                    <img id="pic23" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </td>
              </tr>
              <tr>
                <td>
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest24"
                  >
                    <img id="pic24" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </td>
                <td>
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest25"
                  >
                    <img id="pic25" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </td>
              </tr>
            </table>
          </a-form-item>
          <a-form-item :wrapperCol="{ span: 24 }"  class="form-submit">
            <a-button style="margin-right: 8px" @click="handleCancel3">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  orderFindPage,
  batchTransDispacth,
  updateTransStatus,
  unLoadDispacth,
  findListVehicle,
  findListDriver,
  tltTransportNo,
  saveExcepton,
  untltTransportNo, updateInWarehouseStatus,
  tltTransportInfo, saveReceipt, findListCustomer
} from '@/api/manage'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { put } from '@/utils/upload'

export default {
  name: 'TableList',
  components: {
    STable,
  },
  data() {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: { pageSizeOptions: ['50', '100', '200', '500'] },
      treeData: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '订单号',
          width: '200px',
          align: 'center',
          key: 'transactionNo',
          dataIndex: 'transactionNo',
          fixed: 'left',
        },
        {
          title: '运单号',
          width: '200px',
          align: 'center',
          key: 'transportNo',
          dataIndex: 'transportNo',
        },
        {
          title: '下单时间',
          width: '200px',
          align: 'center',
          key: 'orderDate',
          dataIndex: 'orderDate'
        },
        {
          title: '线路',
          align: 'center',
          width: '200px',
          key: 'lineName',
          dataIndex: 'lineName'
        },
        {
          title: '温度',
          align: 'center',
          key: 'temperature',
          dataIndex: 'temperature',
          width: '150px',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '冷藏'
            } else if (text == 2) {
              ret = '冷冻'
            } else {
              ret = '常温'
            }
            return ret
          },
        },
        {
          title: '运输状态',
          width: '150px',
          align: 'center',
          key: 'transStatus',
          dataIndex: 'transStatus',
          customRender: (text) => {
            let ret = ''
            if (text == 0) {
              ret = '待调度'
            } else if (text == 2) {
              ret = '到达'
            } else {
              ret = '运输中'
            }
            return ret
          },
        },
        {
          title: '取消状态',
          width: '150px',
          key: 'revokeType',
          align: 'center',
          dataIndex: 'revokeType',
          customRender: (text) => {
            let ret = ''
            if (text !=null && text>0) {
              ret = '已取消'
            } else {
              ret = '正常'
            }
            return ret
          }
        },
        {
          title: '回单状态',
          width: '150px',
          align: 'center',
          key: 'status',
          dataIndex: 'status',
          customRender: (text) => {
            let ret = ''
            if (text == 6) {
              ret = '已回单'
            }else {
              ret = '未回单'
            }
            return ret
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '280px',
          align: 'left',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        console.log('loadData.parameter', parameter)
        let param = {}
        param = Object.assign(parameter, this.queryParam)
        param.type = 1
        // param.transStatusIn = [0, 1]
        if(this.queryParam!=null&&this.queryParam.transStatus!=null&&this.queryParam.transStatus!=""){
          param.transStatusIn = []
          param.transStatusIn.push(this.queryParam.transStatus)
        }
        if(this.queryParam!=null&&this.queryParam.status!=null&&this.queryParam.status!=""){
          param.statusIn = []
          if(this.queryParam.status==0){
            param.statusIn=[1,2,3,4,5]
          }else{
            param.statusIn.push(this.queryParam.status)
          }

        }

        param.transportType = 2
        if(this.queryParam.orderDateStart!=null){
          param.orderDateStart = this.queryParam.orderDateStart.format("YYYY-MM-DD")+" 00:00:00"
        }
        if(this.queryParam.orderDateEnd!=null){
          param.orderDateEnd = this.queryParam.orderDateEnd.format("YYYY-MM-DD")+" 23:59:59"
        }
        param.paymentCustomerCode = this.queryParam.paymentCustomerCode
        return orderFindPage(param).then((res) => {
          return res.result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          },
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
        },
      },
      optionAlertShow: false,
      confirmLoading: false,
      visible_add: false,
      visible_add2: false,
      visible_receipt:false,
      form: this.$form.createForm(this),
      tabObj: {}, //当前对象，编辑是用到
      addFlag: true, //true新增false编辑
      expandedKeys: [], //已绑定的keys
      submitKeys: [], //提交保存的key,包括半选中和选中
      thisOrder: {},
      optionsDriverList: [],
      optionsVehicleList: [],
      driverInfoList: [],
      vehicleInfoList: [],
      //isback:true,
      thisException: {},
      scrollHeight: 0,
      qyCustomers:[]
    }
  },
  created() {
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight - 455
    findListCustomer({type:1})
      .then(res => {
        this.qyCustomers = []
        for(let i in res.result){
          this.qyCustomers.push(res.result[i])
        }

      }).catch((e)=>{
      this.$message.error("查询企业客户列表失败")
    })
  },
  filters: {},
  methods: {
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            },
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          },
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null,
        }
        this.optionAlertShow = false
      }
    },
    updateStatus(status) {
      let that = this
      if (this.selectedRows.length <= 0) {
        this.$message.error('请选择订单')
        return false
      }
      let param = {}
      param.type = 2
      param.status = status
      param.orderIds = that.selectedRowKeys
      updateTransStatus(param).then((res) => {
        console.info(res.code)
        if (res.code == 0) {
          that.$message.success('更新成功')
        } else {
          that.$message.error(res.message)
        }
      })
    },
    handleOk() {},
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date()),
      }
    },
    openTransport(record) {
      let that = this
      this.visible_add = true
      this.thisOrder = record
      //查询司机列表
      this.$nextTick(() => {
        findListDriver({ status: 1 }).then((res) => {
          if (res != null && res.result != null) {
            that.optionsDriverList = []
            that.driverInfoList = res.result
            for (let i in res.result) {
              that.optionsDriverList.push({
                id: res.result[i].id + '',
                name: res.result[i].name + '(' + res.result[i].mobile + ')',
              })
            }
          }
        })
        //查询车辆列表
        findListVehicle({ status: 1 }).then((res) => {
          if (res != null && res.result != null) {
            that.optionsVehicleList = []
            that.vehicleInfoList = res.result
            for (let i in res.result) {
              that.optionsVehicleList.push({ id: res.result[i].id + '', vno: res.result[i].vno })
            }
          }
        })
      })
    },
    unTransport(record) {
      //撤回调度
      let that = this
      this.$confirm({
        title: '提示',
        content: '确认撤销之前调度吗 ?',
        onOk: () => {
          let values = {}
          let orderIds = []
          orderIds.push(record.id)
          values.orderIds = orderIds
          values.type = 2
          untltTransportNo(values).then((res) => {
            if (res != null && res.code==0) {
              that.$message.success("撤销成功");
              that.$refs.table.refresh()
            }else{
              that.$message.error(res.message);
            }
          })
        },
        onCancel() {},
      })

    },
    iscandel(record) {
      if (record.type == 1) {
        return false
      } else {
        return true
      }
    },
    handleCancel() {
      this.visible_add = false
    },
    handleChange(value) {
      //司机选择
      let selectedDriver = {}
      for (let i in this.driverInfoList) {
        if (this.driverInfoList[i].id == value) {
          selectedDriver = this.driverInfoList[i]
          break
        }
      }
      let selectedVehicle = {}
      for (let i in this.vehicleInfoList) {
        if (this.vehicleInfoList[i].id == selectedDriver.vehicleId) {
          selectedVehicle = this.vehicleInfoList[i]
          break
        }
      }

      if (selectedVehicle.id == null) {
        this.form.setFieldsValue({
          name: selectedDriver.name,
          mobile: selectedDriver.mobile,
        })
      } else {
        this.form.setFieldsValue({
          name: selectedDriver.name,
          mobile: selectedDriver.mobile,
          vehicleId: selectedVehicle.id + '',
        })
      }
    },
    handleChange2(value) {
      let selectedVehicle = {}
      for (let i in this.vehicleInfoList) {
        if (this.vehicleInfoList[i].id == value) {
          selectedVehicle = this.vehicleInfoList[i]
          break
        }
      }

      this.form.setFieldsValue({
        vehicleId: selectedVehicle.id + '',
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          values.orderIds = []
          values.orderIds[0] = that.thisOrder.id
          values.type = 2
          console.log('Received values of form: ', values)
          tltTransportNo(values).then((res) => {
            if (res != null) {
              console.info(res.code)
              if (res.code == 0) {
                that.$message.success('更新成功')
                that.visible_add = false
                this.$refs.table.refresh()
              } else {
                that.$message.error(res.message)
              }
            }
          })
        }
      })
    },
    headers() {
      let token = Vue.ls.get(ACCESS_TOKEN)
      return {
        token: token,
      }
    },
    exception(record) {
      this.visible_add2 = true
      this.thisException.orderId = record.id
      this.thisException.orderNo = record.orderNo
    },
    handleCancel2() {
      this.visible_add2 = false
    },
    handleCancel3(){
      this.visible_receipt = false
    },
    handleSubmit2(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          values.orderId = that.thisException.orderId

          if (that.thisException.pic1) {
            let pic = {}
            pic.pic = that.thisException.pic1
            pic.cindex = 1
            values.pic1 = pic
          }
          if (that.thisException.pic2) {
            let pic = {}
            pic.pic = that.thisException.pic2
            pic.cindex = 2
            values.pic2 = pic
          }
          if (that.thisException.pic3) {
            let pic = {}
            pic.pic = that.thisException.pic3
            pic.cindex = 3
            values.pic3 = pic
          }
          if (that.thisException.pic4) {
            let pic = {}
            pic.pic = that.thisException.pic4
            pic.cindex = 4
            values.pic4 = pic
          }
          if (that.thisException.pic5) {
            let pic = {}
            pic.pic = that.thisException.pic5
            pic.cindex = 5
            values.pic5 = pic
          }
          values.orderNo = that.thisException.orderNo
          values.businessDate = values.businessDate.format("YYYY-MM-DD HH:mm:ss")

          console.log('Received values of form: ', values)
          saveExcepton(values).then((res) => {
            if (res != null) {
              console.info(res.code)
              if (res.code == 0) {
                that.$message.success('保存成功')
                that.visible_add2 = false
                this.$refs.table.refresh()
              } else {
                that.$message.error(res.message)
              }
            }
          })
        }
      })
    },
    customRequest1(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.thisException.pic1 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic1')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest2(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.thisException.pic2 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic2')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest3(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.thisException.pic3 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic3')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest4(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.thisException.pic4 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic4')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest5(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.thisException.pic5 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic5')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest21(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.thisOrder.pic21 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic21')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest22(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.thisOrder.pic22 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic22')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest23(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.thisOrder.pic23 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic23')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest24(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.thisOrder.pic24 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic24')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest25(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.thisOrder.pic25 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic25')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    handleChange3(e) {},
    async getimgblob(url, id) {
      let token = Vue.ls.get(ACCESS_TOKEN)
      let request = new XMLHttpRequest()
      request.responseType = 'blob'
      request.open('get', url, true)
      // 携带请求头
      request.setRequestHeader('token', token)
      request.onreadystatechange = (e) => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
          document.getElementById(id).src = URL.createObjectURL(request.response)
        }
      }
      request.send(null)
    },
    orderInfo(record) {
      //this.$router.push({path:'/orderServe/orderManagerDetail', query: {"orderSn": record.orderSn}})
      this.$router.push({
        path: '/transport_manage/order_detail_dis',
        query: { orderSn: record.transactionNo, fromroute: 'TltTransport', orgOrderId: record.id },
      })
    },
    receiptOrder(record){
      this.visible_receipt = true
      this.thisOrder.orderId = record.id

    },
    handleSubmit3(e){
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          values.orderId = that.thisOrder.orderId

          if (that.thisOrder.pic21) {
            let pic = {}
            pic.pic = that.thisOrder.pic21
            pic.cindex = 1
            pic.type=4
            values.pic21 = pic
          }
          if (that.thisOrder.pic22) {
            let pic = {}
            pic.pic = that.thisOrder.pic22
            pic.cindex = 2
            pic.type=4
            values.pic22 = pic
          }
          if (that.thisOrder.pic23) {
            let pic = {}
            pic.pic = that.thisOrder.pic23
            pic.cindex = 3
            pic.type=4
            values.pic23 = pic
          }
          if (that.thisOrder.pic24) {
            let pic = {}
            pic.pic = that.thisOrder.pic24
            pic.cindex = 4
            pic.type=4
            values.pic24 = pic
          }
          if (that.thisOrder.pic25) {
            let pic = {}
            pic.pic = that.thisOrder.pic25
            pic.cindex = 5
            pic.type=4
            values.pic25 = pic
          }

          console.log('Received values of form: ', values)
          saveReceipt(values).then((res) => {
            if (res != null) {
              console.info(res.code)
              if (res.code == 0) {
                that.$message.success('保存成功')
                that.visible_receipt = false
                this.$refs.table.refresh()
              } else {
                that.$message.error(res.message)
              }
            }
          })
        }
      })
    },
    handleConfirmPass(rule,value, callback){
      if(value!=null&&value.length>100){
        callback("异常内容过长，请不要超过50字符")
      }
      callback()
    },
  },
}
</script>
<style lang="less" scoped>
.table-operator {
  margin-bottom: 10px;
}
/deep/ .ant-form-item-label {
  width: 100px !important;
  text-align: left;
}

.ant-btn {
  line-height: 0 !important;
}
.ant-table-fixed-columns-in-body {
    visibility: visible !important;
}
.ant-upload img{
  line-height: 16px;
}
/deep/ .ant-upload{
  padding: 0 !important;
}
/deep/ .ant-form-item-control-wrapper{
  width: 73%;
}
/deep/  .form-submit {
  margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
}
.dislodge-script{
 /deep/ .ant-form-item-label label::after{
    content:'' !important;
  }
}
</style>